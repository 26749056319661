@import "src/theme/colors.scss";

.container {
  width: 100%;

  & > * {
    & + * {
      margin-top: 10px;
    }
  }
}

.input {
  font-family: "lic_grotesque" !important;
  background-color: $background-variant-1 !important;
  border: 0 !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  width: 100% !important;
  outline: none !important;
  padding: 15px 25px;
  font-size: 16px !important;

  & input {
    padding: 0 !important;
  }
}
