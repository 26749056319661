@import "src/theme/colors.scss";

.container {
  align-items: center;
  background-color: $primary-variant-1;
  display: flex;
  justify-content: space-between;
  padding: 25px;
  width: 100%;
}

.closeButton {
  display: flex !important;
  height: fit-content !important;
  padding: 0px !important;
  margin-left: 25px;
}

.closeIcon {
  color: $white !important;
  font-size: 18px;
}
