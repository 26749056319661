@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  @media #{$responsive} {
    flex-direction: column;
    width: 100%;
  }
}

.item {
  width: 185px;

  @media #{$responsive} {
    align-items: center;
    display: flex;
    width: 100%;
  }
}

.label {
  margin: 0 auto !important;
  width: fit-content !important;

  @media #{$responsive} {
    margin: 0 !important;
  }
}

.iconWrapper {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: $primary-variant-2;
  margin-bottom: 25px;
  border-radius: 5px;
  height: 190px;
  width: 185px;

  & svg {
    height: 70px;
  }

  @media #{$responsive} {
    margin-right: 15px;
    margin-bottom: unset;
    height: 94px;
    width: 97px;
    min-height: 94px;
    min-width: 97px;

    & svg {
      max-height: 50px;
      max-width: 50px;
    }
  }
}

.selected {
  background-color: lighten($supplemental-variant-1, 25%);
}
