@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.container {
  display: flex;

  @media #{$mobile} {
    flex-direction: column;
  }
}

.modal {
  padding: 0;

  @media #{$mobile} {
    height: fit-content !important;
  }
}

%section {
  flex: 1;
}

.right {
  @extend %section;

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: $primary-variant-2;
  padding: 0 25px;

  @media #{$mobile} {
    padding: 25px 25px 35px;
  }
}

.left {
  @extend %section;

  @media #{$mobile} {
    flex: unset;
    height: 270px;
    position: relative;
  }
}

.submit {
  width: 100%;
}

.logo {
  width: 115px;
  margin: 0 auto 65px;

  @media #{$mobile} {
    width: 85px;
    margin-bottom: 25px;
  }
}

.image {
  height: 100%;

  @media #{$mobile} {
    height: auto;
    width: 100%;
    min-height: 100%;
  }
}

.field {
  background-color: $white !important;
}

.form {
  margin-top: 45px;
  width: 100%;

  @media #{$mobile} {
    margin-top: 25px;
  }
}

.subtitle {
  margin-top: 15px;
  width: 270px !important;
}

.closeButtonWrapper {
  width: 100%;
  padding: 25px 0 0;

  @media #{$mobile} {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 15px 0 0;
    z-index: 10;
  }
}

.closeIcon {
  fill: $primary-variant-1 !important;
}

.closeButton {
  padding: 0 !important;
  margin-left: auto;
}
