@font-face {
  font-family: "lic_grotesque";
  font-display: swap;
  src: url(/fonts/basis-grotesque-regular.woff2) format("woff2"),
       url(/fonts/basis-grotesque-regular.woff) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "lic_grotesque";
  font-display: swap;
  src: url(/fonts/basis-grotesque-medium.woff) format("woff");
  font-weight: 600;
}