.fieldWrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  & + .fieldWrapper {
    margin-top: 15px;
  }
}

.field {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.title {
  margin-bottom: 5px;
}
