.container {
  border-radius: 0 !important;
  overflow: visible !important;
  max-width: unset;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 25px;
  width: min(800px, 100vw) !important;
  overflow: auto;
}
