@import "src/theme/colors.scss";

.radio {
  align-items: center;
  display: flex;
}

.icon {
  height: 27px;
  width: 27px;
}

.container {
  width: 100%;
}

.inline {
  flex-wrap: wrap;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-around;
  width: 100%;
}
