.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.base {
  margin: 0;
  width: fit-content;
  white-space: nowrap;
  transition: color 0.5s ease-in-out;
}

.breakWord {
  overflow-wrap: break-word;
  white-space: normal;
}
