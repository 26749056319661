@import "src/theme/colors.scss";

.container {
  width: 100%;

  & > * {
    & + * {
      margin-top: 10px;
    }
  }
}

.select {
  border-radius: 5px !important;
  background-color: $primary-variant-2;
  width: 100% !important;

  &::before,
  &::after {
    display: none !important;
  }
}

.root {
  font-family: "lic_grotesque" !important;
  border: 0 !important;
  box-shadow: none !important;
  outline: none !important;
  padding: 15px 25px;
  font-size: 16px !important;
}

.dropdown {
  background-color: $primary-variant-2;
  border-radius: 5px !important;
  box-shadow: $card-shadow !important;
  padding: 0px !important;
}

.dropdownItem {
  font-family: "lic_grotesque" !important;
  border: 0 !important;
  outline: none !important;
  padding: 15px 25px;
  font-size: 16px !important;
  color: $black;
  transition: all 0.5s ease-in-out;

  &:hover {
    background-color: $primary-variant-2 !important;
    background-color: $gray25;
  }
}
