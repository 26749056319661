@import "src/theme/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.autocompleteWrapper {
  align-items: center;
  display: flex;
}

.autocomplete {
  font-family: "lic_grotesque" !important;
  background-color: $background-variant-1 !important;
  border: 0 !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  width: 100% !important;
  outline: none !important;
  padding: 15px 25px;
  font-size: 16px !important;
  background-color: $primary-variant-2;
  border: 0 !important;
  border-radius: 5px;
  height: fit-content;
  width: 100%;

  &:focus,
  &:focus-visible,
  &:active {
    outline: none !important;
  }
}

.icon {
  fill: $primary-variant-2;
  font-size: 20px;
}

.mapIcon {
  color: $white;
  font-size: 15px;
  margin-right: 10px;
}

.detail {
  align-items: center;
  display: flex;
  background-color: $secondary-variant-1;
  border-radius: 5px;
  margin-top: 15px;
  padding: 7px 18px;
}

.error {
  margin-top: 15px;
}
