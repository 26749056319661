@import "src/theme/media.scss";

.hidden {
  display: none;
}

.desktop {
  @media #{$desktop} {
    display: block;
  }
}

.mobile {
  @media #{$mobile} {
    display: block;
  }
}

.tablet {
  @media #{$tablet} {
    display: block;
  }
}

.responsive {
  @media #{$responsive} {
    display: block;
  }
}
